
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import { Modal } from "bootstrap";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      listData: [],
      listCompany: [],
      listSekolah: [],
      listCategory: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      inputData: [
        {
          name: "",
          design: {
            front: "",
            back: "",
          },
        },
      ],
      targetDataImage: [
        {
          image1: "",
          image2: "",
        },
      ],
      imageShow: [
        {
          image1: "/media/no-image/No_Image_Horizontal_TKI.png",
          image2: "/media/no-image/No_Image_Horizontal_TKI.png",
        },
      ],
      isEdit: false,
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      aksesSimpanCardDesain: false,
      uploadFotoMultiple: false,
      verifikasiMultipleCondition: false,
      historyUpload: false,
      download: false,
      tambahUser: false,
      verifikasiSingle: false,
      uploadFotoSingle: false,
      editDataSingle: false,
      hapusDataSingle: false,

      showDropdownBtn: false,
      showDropdownBtnHistory: false,
      showDropdownBtnHDownload: false,

      resultImportExcel: false,
      successImport: false,

      listDenganFoto: [
        {
          _id: "false",
          name: "Belum Ada Foto",
        },
        {
          _id: "true",
          name: "Sudah Ada Foto",
        },
      ],
      listValidasi: [
        {
          _id: "true",
          name: "Sudah Verifikasi",
        },
        {
          _id: "false",
          name: "Belum Verifikasi",
        },
      ],

      listItems: [
        {
          _id: 1,
          name: "Nama Siswa",
          nis: "000000000000",
          nisn: "000000000000000",
          placeOfBirth: "Salatiga",
          dateOfBirth: "01 Januari 1999",
          gender: "-",
          grade: "-",
          gradeDetail: "-",
          address: "-",
          tags: "['-' , '-']",
          note: "-",
          isPrint: "Belum Cetak",
          isInternalValid: "Belum Verifikasi",
          isSchoolValid: "Belum Verifikasi",
        },
      ],

      targetData: {
        _id: 1,
        name: "Nama Siswa",
        nis: "000000000000",
        nisn: "000000000000000",
        placeOfBirth: "Salatiga",
        dateOfBirth: "01 Januari 1999",
        gender: "-",
        grade: "-",
        gradeDetail: "-",
        address: "-",
        tags: "['-' , '-']",
        note: "-",
        isPrint: false,
        isInternalValid: false,
        isSchoolValid: false,
      },

      detail: {
        createTime: "",
        creatorId: "",
        updateTime: "",
        editorId: "",
        editorName: "",
        creatorName: "",
        executorName: "",
        deleteBy: "",
        deleteTime: "",
        cardId: "",
        companyId: "",
        userId: "",
        name: "",
        isDelete: false,
        active: false,
        accountNumber: "",
        accountId: "",
        callerName: "",
        callerId: "",
        tags: "",
        nfcId: "",
      },

      targetDataUploadExcel: {
        idSchool: "",
        fileexcel: "",
      },
      errorUploadExcel: {
        sekolah: "",
        file: "",
      },

      schoolIdSelected: "",

      schoolNameImport: "",

      tableDataHistory: [],

      targetDataUploadFotoMulti: {
        idSchool: "",
        listFotoMulltiple: [],
      },

      summary: {
        total: 0,
        photoUrl: 0,
        nothingphotoUrl: 0,
        isSchoolValid: 0,
        isInternalValid: 0,
      },

      showResultUploadMultiple: false,
      resultTotalMultipleUpload: 0,
      resultSuccessMultipleUpload: 0,
      resultFailedMultipleUpload: 0,

      textColorVerifikasiAdmin: "red",
      textColorVerifikasiSekolah: "red",
      textColorCetak: "red",

      schoolIdUpdate: "",
      cardIdUpdate: "",

      namaSekolah: "",

      imageFotoVertikal: "/media/no-image/No_Image_Vertikal_TKI.png",
      imageFotohorizontal: "/media/no-image/No_Image_Horizontal_TKI.png",
      imageFotoHuman: "/media/no-image/No_Image_Photo_TKI.png",
      imageFotoPersonal: "/media/no-image/No_Image_Photo_TKI.png",
      imageFoto: "/media/no-image/No_Image_Photo_TKI.png",
      urlimage1: "/media/no_image.JPG",
      urlimagedepanshow: "/media/no-image/No_Image_Horizontal_TKI.png",
      urlimagebelakangshow: "/media/no-image/No_Image_Horizontal_TKI.png",

      fileInputKey3: 0,
      fileInputKeyMultiple: 0,

      modalShow: false,

      nama: "",
      role: "",
      tag: "",
      nis: "",
      isPhoto: "",
      isSchool: "",
      filterValidasi: false,
      filterPhoto: false,
      // categoryId: "",
      schoolId: "",

      idtext: "",
      // elements:"",
    };
  },

  beforeMount() {
    this.getAllDesign();
    // setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    cekRoleAkses() {
      switch (this.role) {
        case "SUPER_ADMIN":
          this.aksesSimpanCardDesain = true;
          this.uploadFotoMultiple = true;
          this.verifikasiMultipleCondition = true;
          this.download = true;
          this.historyUpload = true;
          this.tambahUser = true;
          this.verifikasiSingle = true;
          this.uploadFotoSingle = true;
          this.editDataSingle = true;
          this.hapusDataSingle = true;
          break;
        case "SALES_LEADER":
          this.aksesSimpanCardDesain = true;
          this.uploadFotoMultiple = false;
          this.verifikasiMultipleCondition = false;
          this.historyUpload = true;
          this.download = true;
          this.tambahUser = false;
          this.verifikasiSingle = false;
          this.uploadFotoSingle = false;
          this.editDataSingle = false;
          this.hapusDataSingle = false;
          break;
        case "SALES_AREA":
          this.aksesSimpanCardDesain = true;
          this.uploadFotoMultiple = false;
          this.verifikasiMultipleCondition = false;
          this.historyUpload = true;
          this.download = true;
          this.tambahUser = false;
          this.verifikasiSingle = false;
          this.uploadFotoSingle = false;
          this.editDataSingle = false;
          this.hapusDataSingle = false;
          break;
        case "CS_LEADER":
          this.aksesSimpanCardDesain = false;
          this.uploadFotoMultiple = false;
          this.verifikasiMultipleCondition = false;
          this.historyUpload = true;
          this.download = true;
          this.tambahUser = false;
          this.verifikasiSingle = false;
          this.uploadFotoSingle = false;
          this.editDataSingle = false;
          this.hapusDataSingle = false;
          break;
        case "CS_MEMBER":
          this.aksesSimpanCardDesain = false;
          this.uploadFotoMultiple = false;
          this.verifikasiMultipleCondition = false;
          this.historyUpload = true;
          this.download = true;
          this.tambahUser = false;
          this.verifikasiSingle = false;
          this.uploadFotoSingle = false;
          this.editDataSingle = false;
          this.hapusDataSingle = false;
          break;
        case "ADMIN_CARD":
          this.aksesSimpanCardDesain = true;
          this.uploadFotoMultiple = true;
          this.verifikasiMultipleCondition = true;
          this.historyUpload = true;
          this.download = true;
          this.tambahUser = true;
          this.verifikasiSingle = true;
          this.uploadFotoSingle = true;
          this.editDataSingle = true;
          this.hapusDataSingle = true;
          break;
        case "IMPLEMENTATOR_LEADER":
          this.aksesSimpanCardDesain = true;
          this.uploadFotoMultiple = true;
          this.verifikasiMultipleCondition = false;
          this.historyUpload = true;
          this.download = true;
          this.tambahUser = true;
          this.verifikasiSingle = false;
          this.uploadFotoSingle = true;
          this.editDataSingle = true;
          this.hapusDataSingle = true;
          break;
        case "IMPLEMENTATOR_MEMBER":
          this.aksesSimpanCardDesain = true;
          this.uploadFotoMultiple = true;
          this.verifikasiMultipleCondition = false;
          this.historyUpload = true;
          this.download = true;
          this.tambahUser = true;
          this.verifikasiSingle = false;
          this.uploadFotoSingle = true;
          this.editDataSingle = true;
          this.hapusDataSingle = true;
          break;
      }
    },
    goToPageHistory() {
      this.$router.push("/superadmin/card-design/history-upload");
    },

    downloadTemplateExcel() {
      const toast = useToast();
      // toast.error("File Belum Ada");
      this.isLoading = true;
      ApiService.getDownload("crmv2/main_card/util/template/download")
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          // console.log(res.data);
          var extension = "xlsx";
          link.setAttribute("download", "Template_Excel" + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;

          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error("Gagal Unduh File");
          this.isLoading = false;
        });
    },

    getAllDesign() {
      this.isLoading = true;
      ApiService.getWithoutSlug(
        `crmv2/main_card/card_design/office/${this.$route.params._id}/all?cartId=${this.$route.params.cartId}&page=0&size=10&sort=createTime&dir=1`
      )
        .then((res) => {
          this.isLoading = false;
          this.inputData = res.data.content.length
            ? res.data.content.filter((prop) => prop.isDelete === false)
            : [
                {
                  createTime: "",
                  name: "",
                  design: {
                    front: "",
                    back: "",
                  },
                },
              ];
          if (res.data.content.length) {
            this.inputData.map((prop, index) => {
              if (!this.imageShow[index])
                this.imageShow[index] = { image1: "", image2: "" };

              ApiService.getWithoutSlug(
                "crmv2/main_image/image/get/base64/" + prop.design.front
              ).then((res) => {
                this.imageShow[index].image1 =
                  "data:image/jpg;base64," + res.data;
              });
              ApiService.getWithoutSlug(
                "crmv2/main_image/image/get/base64/" + prop.design.back
              ).then((res) => {
                this.imageShow[index].image2 =
                  "data:image/jpg;base64," + res.data;
              });
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    saveModal(index) {
      const modal = new Modal(
        document.getElementById("modal-save-design" + index)
      );
      modal.show();
    },
    saveDesign(index) {
      this.isLoading = true;
      const toast = useToast();
      const modal = new Modal(
        document.getElementById("modal-save-design" + index)
      );
      ApiService.postWithData(
        `/crmv2/main_card/card_design/office/${this.$route.params._id}?cartId=${this.$route.params.cartId}`,
        this.inputData[index]
      )
        .then((res) => {
          this.isLoading = false;
          toast.success("Berhasih Menyimpan Desain");
          modal.hide();
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error("Gagal Menyimpan Desain");
        });
    },
    handleEdit() {
      this.isEdit = !this.isEdit;
    },
    saveEdit(id, index) {
      this.isLoading = true;
      const toast = useToast();
      ApiService.putWithData(
        `/crmv2/main_card/card_design/office/${this.$route.params._id}/${id}`,
        this.inputData[index]
      )
        .then((res) => {
          this.getAllDesign();
          this.isLoading = false;
          toast.success("Berhasih Merubah Desain");
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error("Gagal Merubah Desain");
        });
    },
    verifyModal(index) {
      const modal = new Modal(document.getElementById("modal-confirm-design"));
      modal.show();
    },
    verifyDesign() {
      this.isLoading = true;
      const toast = useToast();
      const modal = new Modal(document.getElementById("modal-confirm-design"));
      ApiService.putWithData(
        `/crmv2/order/cart/util/verify/${this.$route.params.cartId}/card-design`,
        {}
      )
        .then((res) => {
          this.isLoading = false;
          modal.hide();
          toast.success("Berhasih Verifikasi Desain");
          this.$emit("tabChange", "#kt_tab_pane_2");
          // this.$router.push(
          //   `/superadmin/cart-product/detail-list-card/${this.$route.params._id}/${this.$route.params.cartId}`
          // );
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error("Gagal Verifikasi Desain");
        });
    },
    addDesign() {
      this.inputData.push({
        name: "",
        design: {
          front: "",
          back: "",
        },
      });

      this.targetDataImage.push({
        image1: "",
        image2: "",
      });

      this.imageShow.push({
        image1: "/media/no-image/No_Image_Horizontal_TKI.png",
        image2: "/media/no-image/No_Image_Horizontal_TKI.png",
      });
    },
    handleFileDesainDepan(event, index) {
      this.imageShow[index].image1 = URL.createObjectURL(event.target.files[0]);
      this.targetDataImage[index].image1 = event.target.files[0];

      let formData = new FormData();
      formData.append("file", this.targetDataImage[index].image1);

      ApiService.postWithData(
        "/crmv2/main_image/image/upload/" + this.$route.params.cartId,
        formData
      ).then((res) => {
        this.inputData[index].design.front = res.data.imgUrl;
      });
    },
    handleFileDesainBelakang(event, index) {
      // console.log(event.target.files[0]);
      // console.log(URL.createObjectURL(event.target.files[0]));
      this.imageShow[index].image2 = URL.createObjectURL(event.target.files[0]);
      this.targetDataImage[index].image2 = event.target.files[0];

      let formData = new FormData();
      formData.append("file", this.targetDataImage[index].image2);

      ApiService.postWithData(
        "/crmv2/main_image/image/upload/" + this.$route.params.cartId,
        formData
      ).then((res) => {
        this.inputData[index].design.back = res.data.imgUrl;
      });
    },
    handleBlobResponse(blobFileData, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      const d = Date.now();
      link.href = url;
      link.setAttribute("download", "DocCardMapping_" + d + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },
    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    deleteRow(item) {
      this.isLoading = true;
      const toast = useToast();
      ApiService.delete(
        `crmv2/main_card/card_design/office/${this.$route.params._id}/${item}`
      )
        .then((res) => {
          this.isLoading = false;
          toast.success("Hapus Berhasil");
          this.getAllDesign();
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error("Gagal Hapus");
        });
    },
    changeModal() {},

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      //   this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      //   this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      //   this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      //   this.getData(this.paging.size, page);
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateTime(val) {
      if (val) {
        let text = val;
        let result = text.includes("T");
        let jam = "";
        if (result == true) {
          // let text = "2022-04-05T09:45:32.719896";
          let val = text.split("T");
          let subst = val[1].substr(0, 8);
          jam = subst;
        } else {
          jam = "hh:mm";
        }
        return moment(val).format("YYYY-MM-DD " + jam);
      } else {
        return "-";
      }
    },
    dateOnly(val) {
      console.log(val);
      if (val) {
        console.log(moment(val).format("DD-MM-YYYY "));
        return moment(val).format("DD-MM-YYYY ");
      } else {
        return "-";
      }
    },

    getImageSiswa(val1, val2) {
      console.log(val2);
      // if(val2 === "" || val2 == null){
      //   return this.imageFotoHuman
      // }else{
      //   return "data:image/jpg;base64,"+val1;
      // }
      return "data:image/jpg;base64," + val1;
    },
    getStatusCetak(val) {
      if (val == true) {
        return "Sudah Dicetak";
      } else {
        return "Belum Dicetak";
      }
    },
    getStatusVerifikasiSekolah(val) {
      if (val == true) {
        return "Sudah Verifikasi";
      } else {
        return "Belum Verifikasi";
      }
    },
    getStatusVerifikasiAdmin(val) {
      if (val == true) {
        return "Sudah Verifikasi";
      } else {
        return "Belum Verifikasi";
      }
    },

    colorStatus(val) {
      if (val == true) {
        return "green";
      } else {
        return "red";
      }
    },

    convertTags(val) {
      console.log(val.length);
      const array = val;
      let text = "";
      if (val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          if (i == 0) {
            text += val + " ";
          } else {
            text != val[i] + ", ";
          }
        }
      }
      console.log(text);
      return text;
    },
  },
});
