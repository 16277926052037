
import { defineComponent, onMounted, reactive } from "vue";
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { Modal } from "bootstrap";
import { useToast } from "vue-toastification";
export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    tab: String,
  },

  data() {
    return {
      tableData: [
        {
          transactionId: "12i73172iu",
          companyName: "Sekolah TKi",
          name: "Kartu jajan",
          total: 200,
          amount: 2000000,
          status: "unavailable",
          note: "note",
        },
        {
          transactionId: "12i73172iu",
          companyName: "Sekolah TKi",
          name: "Kartu jajan",
          total: 200,
          amount: 2000000,
          status: "waiting",
          note: "note",
        },
        {
          transactionId: "12i73172iu",
          companyName: "Sekolah TKi",
          name: "Kartu jajan",
          total: 200,
          amount: 2000000,
          status: "complete",
          note: "note",
        },
      ],
      imageData: [
        {
          name: "",
          design: {
            front: "",
            back: "",
          },
        },
      ],
      imageShow: [
        {
          image1: "/media/no-image/No_Image_Horizontal_TKI.png",
          image2: "/media/no-image/No_Image_Horizontal_TKI.png",
        },
      ],
      nama: "",
      role: "",
      tag: "",
      nis: "",
      isPhoto: "",
      isSchool: "",
      filterValidasi: false,
      filterPhoto: false,
      listData: [],
      listCompany: [],
      listSekolah: [],
      listCategory: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      urlimage1: "/assets/images/no_image.JPG",
      listDenganFoto: [
        {
          _id: "false",
          name: "Belum Ada Foto",
        },
        {
          _id: "true",
          name: "Sudah Ada Foto",
        },
      ],
      listValidasi: [
        {
          _id: "true",
          name: "Sudah Verifikasi",
        },
        {
          _id: "false",
          name: "Belum Verifikasi",
        },
      ],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      dir: 1,
      sorting: "sort=name&dir=1",
      companyId: "",
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    this.getData(this.paging.size, this.paging.page);
    this.getAllDesign();
    // this.getListSekolah();
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    this.cekRoleAkses();
    this.getListCompany();
    // setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    sortnfc() {
      if (this.dir == 1) {
        this.sorting = "sort=nfc_id&dir=" + this.dir;
        this.dir = -1;
      } else {
        this.sorting = "sort=nfc_id&dir=" + this.dir;
        this.dir = 1;
      }
      this.getData(this.paging.size, this.paging.page);
    },
    verifyModal() {
      const modal = new Modal(document.getElementById("modal-confirm-data"));
      modal.show();
    },
    confirmStep() {
      this.isLoading = true;
      const toast = useToast();
      const modal = new Modal(document.getElementById("modal-confirm-data"));
      ApiService.putWithData(
        `/crmv2/order/cart/util/verify/${this.$route.params.cartId}/card-print`,
        {}
      )
        .then((res) => {
          this.isLoading = false;
          modal.hide();
          toast.success("Berhasil Verifikasi Data");
          this.$router.push(`/superadmin/cart-product`);
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error("Gagal Verifikasi Data");
        });
    },
    cekRoleAkses() {},
    getAllDesign() {
      if (this.$props.tab == "#kt_tab_pane_3") {
        this.isLoading = true;
        ApiService.getWithoutSlug(
          `crmv2/main_card/card_design/office/${this.$route.params._id}/all?cartId=${this.$route.params.cartId}&page=0&size=10&sort=createTime&dir=1`
        )
          .then((res) => {
            this.isLoading = false;
            this.imageData = res.data.content.length
              ? res.data.content.filter((prop) => prop.isDelete === false)
              : [
                  {
                    createTime: "",
                    name: "",
                    design: {
                      front: "",
                      back: "",
                    },
                  },
                ];
            if (res.data.content.length) {
              this.imageData.map((prop, index) => {
                if (!this.imageShow[index])
                  this.imageShow[index] = { image1: "", image2: "" };

                ApiService.getWithoutSlug(
                  "crmv2/main_image/image/get/base64/" + prop.design.front
                ).then((res) => {
                  this.imageShow[index].image1 =
                    "data:image/jpg;base64," + res.data;
                });
                ApiService.getWithoutSlug(
                  "crmv2/main_image/image/get/base64/" + prop.design.back
                ).then((res) => {
                  this.imageShow[index].image2 =
                    "data:image/jpg;base64," + res.data;
                });
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
          });
      }
    },
    getData(size, page) {
      let companyId = "";
      let nama = "";
      let nis = "";
      let tag = "";
      let isPhoto = "";
      let isSchool = "";
      let valcategoryId = "";
      // if (this.categoryId) {
      //   valcategoryId = this.categoryId;
      // }
      // if (this.companyId) {
      //   companyId = this.companyId;
      // }
      if (this.nama) {
        nama = "&name=" + this.nama;
      }
      if (this.nis) {
        nis = "&nis=" + this.nis;
      }
      if (this.tag) {
        tag = "&tag=" + this.tag;
      }
      if (this.isPhoto) {
        isPhoto = "&isPhoto=" + this.filterPhoto;
      }
      if (this.isSchool) {
        isSchool = "&isSchool=" + this.filterValidasi;
      }
      if (this.$props.tab == "#kt_tab_pane_3") {
        this.isLoading = true;
        ApiService.getWithoutSlug(
          "crmv2/main_card/card_data/office/" +
            this.$route.params._id +
            "/all?cartId=" +
            this.$route.params.cartId +
            "&page=" +
            page +
            nama +
            nis +
            tag +
            isPhoto +
            isSchool +
            "&size=" +
            size +
            "&sort=name&dir=1"
        )
          .then(({ data }) => {
            // this.tableData = data.content;
            // localStorage.setItem("listData", JSON.stringify(data.content));
            this.paging.page = data.page;
            this.paging.size = data.size;
            this.paging.totalElements = data.totalElements;
            this.paging.totalPages = data.totalPages;
            this.isLoading = false;
            let content = data.content;
            this.listData = content;

            content.map((prop, index) => {
              if (prop.photoUrl) {
                setTimeout(() => {
                  ApiService.getWithoutSlug(
                    "crmv2/main_image/image/get/base64/" + prop.photoUrl
                  )
                    .then((res) => {
                      // console.log("res" + index, res.data);
                      content[index].photoUrl =
                        "data:image/jpg;base64," + res.data;
                      // console.log(content, index);
                      this.tableData = content;
                      this.$forceUpdate();
                    })
                    .catch((e) => {
                      return this.urlimage1;
                    });
                }, 500);
              }
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
          });
      }
    },
    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
    },
    getListCompany() {
      ApiService.getWithoutSlug("crmv2/main_card/util/combo_company").then(
        ({ data }) => {
          this.listCompany = data;
          localStorage.setItem("listCompany", JSON.stringify(data));
        }
      );
    },
    pilihCompany(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.companyId = event;
      } else {
        this.companyId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("companyId", JSON.stringify(event));
      //   this.getData(this.paging.size, this.paging.page);
    },

    clickview(val) {},

    autoTab(input, len, e, index1) {
      console.log(input);
      console.log(len);
      console.log(e);
      console.log(index1);
      // const index = this.findIndex(e.target);
      // const index = this.findIndex(e.target);
      // console.log(index);
      this.moveFocus(index1 + 1);
      // if (input.value.length >= len) {
      //     this.moveFocus(index + 1);
      // }
    },

    findIndex(target) {
      return [].findIndex.call(this.elements, (e) => e === target);
    },

    moveFocus(index) {
      console.log(index);
      console.log(document.getElementsByClassName("input-items")[index]);
      let text = document.getElementsByClassName("input-items")[index];
      // text.focus();
      // if (this.elements[index]) {
      //     this.elements[index].focus();
      // }
    },

    elements() {
      return document.getElementsByClassName("input-items");
    },
    pilihFilterFoto(event) {
      // console.log(event)
      if (event) {
        console.log(event);
        if (event == "false") {
          this.filterPhoto = false;
        } else {
          this.filterPhoto = true;
        }
        this.isPhoto = event;
      } else {
        this.isPhoto = "";
      }
      this.getData(10, 0);
    },
    pilihFilterValidasi(event) {
      console.log(event);
      if (event) {
        console.log(event);
        if (event == "false") {
          this.filterValidasi = false;
        } else {
          this.filterValidasi = true;
        }
        this.isSchool = event;
      } else {
        this.isSchool = "";
      }
      this.getData(10, 0);
    },
    searchItemTableData(id, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          return myArray[i];
        }
      }
    },
    searchItemNextTableData(id, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          var n = i + 1;
          if (n >= myArray.length) {
            return myArray[i];
          } else {
            return myArray[n];
          }
        }
      }
    },
    dateOnly(val) {
      console.log(val);
      if (val) {
        console.log(moment(val).format("DD-MM-YYYY "));
        return moment(val).format("DD-MM-YYYY ");
      } else {
        return "-";
      }
    },
    convertTags(val) {
      const array = val;
      let text = "";
      if (val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          if (i == 0) {
            text += val + " ";
          } else {
            text != val[i] + ", ";
          }
        }
      }
      return text;
    },
    colorStatus(val) {
      if (val == true) {
        return "green";
      } else {
        return "red";
      }
    },
    getStatusCetak(val) {
      if (val == true) {
        return "Sudah Dicetak";
      } else {
        return "Belum Dicetak";
      }
    },
    getStatusVerifikasiSekolah(val) {
      if (val == true) {
        return "Sudah Verifikasi";
      } else {
        return "Belum Verifikasi";
      }
    },
    setID(val) {
      return "textfield_" + val;
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      // if (this.companyId) {
      // companyId = this.companyId;
      const page = this.paging.page;
      this.getData(this.paging.size, page);
      // }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    cekStatusNFC(val) {
      let length = val.length;
      if (length > 20) {
        return "Belum";
      } else {
        return "Sudah";
      }
    },
  },
});
