
import { defineComponent, onMounted, reactive } from "vue";
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { Modal } from "bootstrap";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { useToast } from "vue-toastification";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  name: "kt-widget-12",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    tab: String,
  },

  data() {
    return {
      tabName: this.$props.tab,
      tableData: [
        {
          transactionId: "12i73172iu",
          companyName: "Sekolah TKi",
          name: "Kartu jajan",
          total: 200,
          amount: 2000000,
          status: "unavailable",
          note: "note",
        },
        {
          transactionId: "12i73172iu",
          companyName: "Sekolah TKi",
          name: "Kartu jajan",
          total: 200,
          amount: 2000000,
          status: "waiting",
          note: "note",
        },
        {
          transactionId: "12i73172iu",
          companyName: "Sekolah TKi",
          name: "Kartu jajan",
          total: 200,
          amount: 2000000,
          status: "complete",
          note: "note",
        },
      ],
      formData: {
        name: "",
        gender: "",
        dateOfBirth: "",
        placeOfBirth: "",
        address: "",
        nis: "",
        nisn: "",
        grade: "",
        gradeDetail: "",
        tags: [""],
        note: "",
        isSchoolValid: false,
        isInternalValid: false,
        isPrint: false,
      },
      listUsers: [],
      userSelected: [],
      userSelectAll: false,
      targetDataImage: {
        fotopersonal: "",
      },
      targetDataUploadFotoMulti: {
        listFotoMulltiple: [],
      },
      listDenganFoto: [
        {
          _id: "false",
          name: "Belum Ada Foto",
        },
        {
          _id: "true",
          name: "Sudah Ada Foto",
        },
      ],
      listValidasi: [
        {
          _id: "true",
          name: "Sudah Verifikasi",
        },
        {
          _id: "false",
          name: "Belum Verifikasi",
        },
      ],
      summary: {
        total: 0,
        photoUrl: 0,
        nothingphotoUrl: 0,
        isSchoolValid: 0,
        isInternalValid: 0,
      },
      resultSuccessMultipleUpload: "",
      resultFailedMultipleUpload: "",
      resultTotalMultipleUpload: "",
      showResultUploadMultiple: false,
      fileInputKey3: 0,
      fileInputKeyMultiple: 0,
      imageFotoPersonal: "/media/no-image/No_Image_Photo_TKI.png",
      urlimage1: "/assets/images/no_image.JPG",
      isEdit: false,
      cardId: "",
      uploadModal: false,
      showAddDropdownBtn: false,
      resultImportExcel: false,
      successImport: false,
      nama: "",
      role: "",
      tag: "",
      nis: "",
      isPhoto: "",
      isSchool: "",
      filterValidasi: false,
      filterPhoto: false,
      targetDataUploadExcel: {
        idSchool: "",
        fileexcel: "",
      },
      errorUploadExcel: {
        sekolah: "",
        file: "",
      },
      listData: [],
      listCompany: [],
      listSekolah: [],
      listCategory: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      paging2: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      dir: 1,
      sorting: "sort=name&dir=1",
      companyId: "",
    };
  },

  beforeMount() {
    // if (this.tabName == "#kt_tab_pane_2") {
    this.getData(this.paging.size, this.paging.page);
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    this.getListCompany();
    // }
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    log() {
      console.log("asdasda", this.tab);
    },
    // userSelect() {
    //   const arr: any[] = [];
    //   this.userSelected = [];
    //   if (!this.userSelectAll) {
    //     this.listUsers.map((prop) => {
    //       arr.push(prop);
    //     });
    //     this.userSelected = arr;
    //   }
    // },
    // sortnfc() {
    //   if (this.dir == 1) {
    //     this.sorting = "sort=nfc_id&dir=" + this.dir;
    //     this.dir = -1;
    //   } else {
    //     this.sorting = "sort=nfc_id&dir=" + this.dir;
    //     this.dir = 1;
    //   }
    //   this.getData(this.paging.size, this.paging.page);
    // },
    showDropdown() {
      if (this.showAddDropdownBtn == true) {
        this.showAddDropdownBtn = false;
      } else {
        this.showAddDropdownBtn = true;
      }
    },
    verifikasiMultiple() {
      // this.showDropdownBtnHistory = false;
      // this.showDropdownBtn = false;
      const toast = useToast();
      // if (this.schoolIdSelected) {
      this.isLoading = true;
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_data/office/" +
          this.$route.params._id +
          "/summary/total?cartId=" +
          this.$route.params.cartId
      )
        .then((res) => {
          this.summary.total = res.data.total;
          this.summary.photoUrl = res.data.photoUrl;
          this.summary.isSchoolValid = res.data.isSchoolValid;
          this.summary.isInternalValid = res.data.isInternalValid;
          this.summary.nothingphotoUrl = res.data.total - res.data.photoUrl;
          // this.detail = res.data;
          console.log(this.summary);

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
      const modal = new Modal(
        document.getElementById("kt_modal_verifikasi_multiple")
      );
      modal.show();
      // } else {
      //   toast.error("Pilih Sekolah terlebih dahulu");
      // }
    },
    simpanVerifikasiMultiple() {
      this.isLoading = true;
      const toast = useToast();

      ApiService.putWithData(
        "crmv2/main_card/card_data/office/" +
          this.$route.params._id +
          "/validation/school/all?status=true&cartId=" +
          this.$route.params.cartId,
        {}
      )
        .then((res) => {
          // cekSuccess = true;
          toast.success("Berhasil Verifikasi Semua Data Siswa");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          this.isLoading = false;
        });
    },
    verifikasiPersonal(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin Verifikasi?",
        text: `Cek kembali semua data sebelum verifikasi`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, Verifikasi !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.verifikasiRow(item);
        }
      });
    },
    verifikasiRow(item) {
      this.formData = item;
      this.formData.isSchoolValid = true;
      this.isLoading = true;
      const toast = useToast();

      ApiService.putWithData(
        "/crmv2/main_card/card_data/office/" +
          item.schoolId +
          "/validation/school/" +
          item._id +
          "?status=true",
        {}
      )
        .then((res) => {
          // cekSuccess = true;
          toast.success("Berhasil Verifikasi Data Siswa");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        });
    },

    verifyModal() {
      const modal = new Modal(document.getElementById("modal-confirm"));
      modal.show();
    },
    confirmStep() {
      this.isLoading = true;
      const toast = useToast();
      const modal = new Modal(document.getElementById("modal-confirm"));
      ApiService.putWithData(
        `/crmv2/order/cart/util/verify/${this.$route.params.cartId}/card-data`,
        {}
      )
        .then((res) => {
          this.isLoading = false;
          modal.hide();
          toast.success("Berhasil Verifikasi Data");
          this.$emit("tabChange", "#kt_tab_pane_3");
          // this.$router.push(
          //   `/superadmin/cart-product/detail-list-card/${this.$route.params._id}/${this.$route.params.cartId}`
          // );
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error("Gagal Verifikasi Data");
        });
    },
    cekRoleAkses() {},
    addUserExisting() {
      const toast = useToast();
      this.isLoading = true;
      ApiService.postWithData(
        "/crmv2/main_card/card_data/office/" +
          this.$route.params._id +
          "/multiple" +
          "?cartId=" +
          this.$route.params.cartId,
        this.userSelected
      )
        .then((res) => {
          toast.success("Berhasil Menambahkan Siswa");
          var modal = new Modal(
            document.getElementById("kt_modal_add_existing")
          );
          modal.hide();
          this.getData(this.paging.size, this.paging.page);
          this.showAddDropdownBtn = false;
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          toast.error(e.response.data.detail);
        });
    },
    getDefaultUser(size, page) {
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_data/office/" +
          this.$route.params._id +
          "/all?" +
          "&page=" +
          page +
          "&size=" +
          size +
          "&sort=name&dir=1"
      )
        .then(({ data }) => {
          // this.tableData = data.content;
          this.listUsers = data.content;
          // localStorage.setItem("listData", JSON.stringify(data.content));
          this.paging2.page = data.page;
          this.paging2.size = data.size;
          this.paging2.totalElements = data.totalElements;
          this.paging2.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    handlefilefotomultiple(event) {
      if (event.target.files[0]) {
        this.targetDataUploadFotoMulti.listFotoMulltiple = event.target.files;
      } else {
        this.fileInputKeyMultiple++;
      }
    },
    simpanFotoMultiple() {
      const toast = useToast();
      if (this.targetDataUploadFotoMulti.listFotoMulltiple.length > 0) {
        this.isLoading = true;

        let formData = new FormData();

        for (
          var i = 0;
          i < this.targetDataUploadFotoMulti.listFotoMulltiple.length;
          i++
        ) {
          let file = this.targetDataUploadFotoMulti.listFotoMulltiple[i];
          formData.append("files", file);
        }

        // let formData = new FormData();
        // formData.append("file", this.targetDataImage.fotopersonal);

        ApiService.postWithData(
          "crmv2/main_image/card/office/upload/" + this.$route.params._id,
          formData
        )
          .then((res) => {
            // cekSuccess = true;
            toast.info("Proses Upload Data Foto Selesai");

            // location.reload();
            // const modal = new Modal(
            //   document.getElementById("kt_modal_upload_foto_multiple")
            // );
            // modal.hide();

            this.showResultUploadMultiple = true;
            this.resultSuccessMultipleUpload = res.data.process.total;
            this.resultFailedMultipleUpload = res.data.failed.total;
            this.resultTotalMultipleUpload =
              res.data.process.total + res.data.failed.total;
            this.isLoading = false;
            this.getData(this.paging.size, this.paging.page);
            // setTimeout(() => this.getData(this.paging.size, this.paging.page), 2000);
          })
          .catch((e) => {
            const modal = new Modal(
              document.getElementById("kt_modal_upload_foto_multiple")
            );
            modal.show();
            toast.error(e.response.data.detail);
            this.isLoading = false;
          });
      } else {
        toast.error("File Kosong");
      }
    },
    uploadPersonal(item) {
      this.formData = item;
      this.cardId = item._id;
      this.imageFotoPersonal = "/media/no-image/No_Image_Photo_TKI.png";
      this.targetDataImage.fotopersonal = "";
      this.fileInputKey3++;
    },
    handleFotoSiswaPersonal(event) {
      const toast = useToast();
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        console.log(myArray[1]);
        if (
          myArray[1].toLowerCase() == "jpg" ||
          myArray[1].toLowerCase() == "jpeg" ||
          myArray[1].toLowerCase() == "png"
        ) {
          this.imageFotoPersonal = URL.createObjectURL(event.target.files[0]);
          this.targetDataImage.fotopersonal = event.target.files[0];
          // this.targetDataUploadExcel.fileexcel = event.target.files[0];
        } else {
          toast.error("File Hanya .jpg .jpeg .png");
          this.fileInputKey3++;
          // this.errorUploadExcel.file = "File Wajib Isi dan File Hanya .xlsx";
        }
      } else {
        toast.error("File Kosong");
        this.fileInputKey3++;
      }
    },
    simpanUploadPersonal() {
      const toast = useToast();
      if (this.targetDataImage.fotopersonal == "") {
        toast.error("File Tidak Boleh Kosong");
      } else {
        this.isLoading = true;

        let formData = new FormData();
        formData.append("file", this.targetDataImage.fotopersonal);

        ApiService.postWithData(
          "crmv2/main_image/card/office/upload/" +
            this.$route.params._id +
            "/" +
            this.cardId,
          formData
        )
          .then((res) => {
            // cekSuccess = true;
            toast.success("Berhasil Upload Foto Siswa");

            // location.reload();
            setTimeout(
              () => this.getData(this.paging.size, this.paging.page),
              2000
            );
            // setTimeout(() => this.isLoading = false;, 2000);
            // setTimeout(() => this.basketAddSuccess = false, 2000);
          })
          .catch((e) => {
            toast.error(e.response.data.detail);
            this.getData(this.paging.size, this.paging.page);
            this.isLoading = false;
          });
      }
    },
    downloadFunction() {
      const toast = useToast();

      this.isLoading = true;
      ApiService.getDownload(
        `crmv2/main_card/card_data/office/${this.$route.params._id}/download?cartId=${this.$route.params.cartId}`
      )
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          console.log(url, " ini sekul");
          const link = document.createElement("a");
          link.href = url;

          // console.log(res.data);
          var extension = "xlsx";
          link.setAttribute("download", "Data_Kartu." + extension); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error("Gagal Unduh File");
          this.isLoading = false;
        });
    },
    handleEdit(item) {
      this.formData = item;
      this.isEdit = true;
      this.cardId = item._id;

      if (item.dateOfBirth.length > 17) {
        this.formData.dateOfBirth = item.dateOfBirth.substring(0, 10);
      } else {
        // console.log('dateOfBirth',item.dateOfBirth)
        var arraydate = item.dateOfBirth.split("-");
        if (arraydate[0].length == 2) {
          this.formData.dateOfBirth =
            arraydate[2] + "-" + arraydate[1] + "-" + arraydate[0];
        } else {
          this.formData.dateOfBirth = item.dateOfBirth;
        }
      }
    },
    chooseUserModalChange() {
      const modal = new Modal(document.getElementById("kt_modal_add_existing"));
      modal.show();
      this.getDefaultUser(this.paging.size, this.paging.page);
    },
    addUserModalChange() {
      const modal = new Modal(document.getElementById("kt_modal_form_add"));
      modal.show();
      this.showAddDropdownBtn = false;
      this.isEdit = false;
      this.formData = {
        name: "",
        gender: "",
        dateOfBirth: "",
        placeOfBirth: "",
        address: "",
        nis: "",
        nisn: "",
        grade: "",
        gradeDetail: "",
        tags: [""],
        note: "",
        isSchoolValid: false,
        isInternalValid: false,
        isPrint: false,
      };
    },
    addUserByForm() {
      const toast = useToast();
      this.isLoading = true;
      let data = this.formData;
      if (this.isEdit) {
        ApiService.putWithData(
          "/crmv2/main_card/card_data/office/" +
            this.$route.params._id +
            "/" +
            this.cardId +
            "?cartId=" +
            this.$route.params.cartId,
          data
        )
          .then((res) => {
            toast.success("Berhasil Menambahkan Siswa");
            var modal = new Modal(
              document.getElementById("kt_modal_upload_excel")
            );
            modal.hide();
            this.getData(this.paging.size, this.paging.page);
            this.isLoading = false;
          })
          .catch((e) => {
            this.isLoading = false;
            toast.error(e.response.data.detail);
          });
      } else {
        ApiService.postWithData(
          "/crmv2/main_card/card_data/office/" +
            this.$route.params._id +
            "?cartId=" +
            this.$route.params.cartId,
          data
        )
          .then((res) => {
            toast.success("Berhasil Menambahkan Siswa");
            var modal = new Modal(
              document.getElementById("kt_modal_upload_excel")
            );
            modal.hide();
            this.getData(this.paging.size, this.paging.page);
            this.isLoading = false;
          })
          .catch((e) => {
            this.isLoading = false;
            toast.error(e.response.data.detail);
          });
      }
    },
    uploadModalChange() {
      const modal = new Modal(document.getElementById("kt_modal_upload_excel"));
      modal.show();
      this.showAddDropdownBtn = false;
    },
    handlefileexcel(event) {
      const toast = useToast();
      console.log(event.target.files[0].name);
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        console.log(myArray[1]);
        if (myArray[1] == "xlsx") {
          this.targetDataUploadExcel.fileexcel = event.target.files[0];
        } else {
          toast.error("File Hanya .xlsx");
          this.errorUploadExcel.file = "File Wajib Isi dan File Hanya .xlsx";
        }
      } else {
        toast.error("File Kosong");
      }
    },
    importExcelVerifikasi() {
      const toast = useToast();
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.targetDataUploadExcel.fileexcel);

      ApiService.postWithData(
        "crmv2/main_card/card_upload/office/" +
          this.$route.params._id +
          "/upload" +
          "?cartId=" +
          this.$route.params.cartId,
        formData
      )
        .then((res) => {
          toast.success("Berhasil Upload Dokumen");
          this.targetDataUploadExcel.fileexcel = "";
          this.getData(this.paging.size, this.paging.page);
          // var modal = new Modal(document.getElementById("kt_modal_upload_excel"));
          // modal.hide();

          // var modal2 = new Modal(document.getElementById("kt_modal_history"));
          // modal2.show();
          // this.$router.push("/superadmin/card-design/history-upload");

          this.resultImportExcel = true;
          this.successImport = true;
          this.isLoading = false;
          // items.fileInputKey++;
          // hideModal(uploadModalRef.value);
          // isLoading.value = false;
          // items.disableButton = false;

          // location.reload();
        })
        .catch((e) => {
          this.resultImportExcel = true;
          this.successImport = false;
          this.isLoading = false;
          toast.error(e.response.data.detail);
        });
    },
    getData(size, page) {
      console.log("props", this.tab);
      // store.dispatch(Actions.VERIFY_AUTH);

      let companyId = "";
      let nama = "";
      let nis = "";
      let tag = "";
      let isPhoto = "";
      let isSchool = "";
      let valcategoryId = "";
      // if (this.categoryId) {
      //   valcategoryId = this.categoryId;
      // }
      // if (this.companyId) {
      //   companyId = this.companyId;
      // }
      if (this.nama) {
        nama = "&name=" + this.nama;
      }
      if (this.nis) {
        nis = "&nis=" + this.nis;
      }
      if (this.tag) {
        tag = "&tag=" + this.tag;
      }
      if (this.isPhoto) {
        isPhoto = "&isPhoto=" + this.filterPhoto;
      }
      if (this.isSchool) {
        isSchool = "&isSchool=" + this.filterValidasi;
      }
      if (this.$props.tab == "#kt_tab_pane_2") {
        this.isLoading = true;
        ApiService.getWithoutSlug(
          "crmv2/main_card/card_data/office/" +
            this.$route.params._id +
            "/all?cartId=" +
            this.$route.params.cartId +
            "&page=" +
            page +
            nama +
            nis +
            tag +
            isPhoto +
            isSchool +
            "&size=" +
            size +
            "&sort=name&dir=1"
        )
          .then(({ data }) => {
            // this.tableData = data.content;
            // localStorage.setItem("listData", JSON.stringify(data.content));
            this.paging.page = data.page;
            this.paging.size = data.size;
            this.paging.totalElements = data.totalElements;
            this.paging.totalPages = data.totalPages;
            let content = data.content;
            this.listData = content;
            this.isLoading = false;

            content.map((prop, index) => {
              if (prop.photoUrl) {
                setTimeout(() => {
                  ApiService.getWithoutSlug(
                    "crmv2/main_image/image/get/base64/" + prop.photoUrl
                  )
                    .then((res) => {
                      // console.log("res" + index, res.data);
                      content[index].photoUrl =
                        "data:image/jpg;base64," + res.data;
                      // console.log(content, index);
                      this.tableData = content;
                      this.$forceUpdate();
                    })
                    .catch((e) => {
                      return this.urlimage1;
                    });
                }, 500);
              }
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
          });
      }
    },
    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
    },
    getListCompany() {
      ApiService.getWithoutSlug("crmv2/main_card/util/combo_company").then(
        ({ data }) => {
          this.listCompany = data;
          localStorage.setItem("listCompany", JSON.stringify(data));
        }
      );
    },
    pilihCompany(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.companyId = event;
      } else {
        this.companyId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("companyId", JSON.stringify(event));
      //   this.getData(this.paging.size, this.paging.page);
    },
    handleDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(id);
        }
      });
    },
    deleteRow(id) {
      this.isLoading = true;
      const toast = useToast();
      ApiService.delete(
        `crmv2/main_card/card_data/office/${this.$route.params._id}/${id}`
      )
        .then((res) => {
          this.isLoading = false;
          toast.success("Hapus Berhasil");
          this.getData(this.paging.size, this.paging.page);
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error("Gagal Hapus");
        });
    },
    clickview(val) {},
    pilihFilterFoto(event) {
      // console.log(event)
      if (event) {
        console.log(event);
        if (event == "false") {
          this.filterPhoto = false;
        } else {
          this.filterPhoto = true;
        }
        this.isPhoto = event;
      } else {
        this.isPhoto = "";
      }
      this.getData(10, 0);
    },
    pilihFilterValidasi(event) {
      console.log(event);
      if (event) {
        console.log(event);
        if (event == "false") {
          this.filterValidasi = false;
        } else {
          this.filterValidasi = true;
        }
        this.isSchool = event;
      } else {
        this.isSchool = "";
      }
      this.getData(10, 0);
    },
    findIndex(target) {
      return [].findIndex.call(this.elements, (e) => e === target);
    },

    elements() {
      return document.getElementsByClassName("input-items");
    },

    searchItemTableData(id, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          return myArray[i];
        }
      }
    },
    searchItemNextTableData(id, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          var n = i + 1;
          if (n >= myArray.length) {
            return myArray[i];
          } else {
            return myArray[n];
          }
        }
      }
    },

    setID(val) {
      return "textfield_" + val;
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    nextPage2() {
      const page = this.paging2.page + 1;
      this.getDefaultUser(this.paging2.size, page);
    },
    prevPage2() {
      const page = this.paging2.page - 1;
      this.getDefaultUser(this.paging2.size, page);
    },
    changePageSize2(e) {
      const page = this.paging2.page;
      this.getDefaultUser(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateOnly(val) {
      console.log(val);
      if (val) {
        console.log(moment(val).format("DD-MM-YYYY "));
        return moment(val).format("DD-MM-YYYY ");
      } else {
        return "-";
      }
    },
    cekStatusNFC(val) {
      let length = val.length;
      if (length > 20) {
        return "Belum";
      } else {
        return "Sudah";
      }
    },
    getStatusCetak(val) {
      if (val == true) {
        return "Sudah Dicetak";
      } else {
        return "Belum Dicetak";
      }
    },
    getStatusVerifikasiSekolah(val) {
      if (val == true) {
        return "Sudah Verifikasi";
      } else {
        return "Belum Verifikasi";
      }
    },
    getStatusVerifikasiAdmin(val) {
      if (val == true) {
        return "Sudah Verifikasi";
      } else {
        return "Belum Verifikasi";
      }
    },

    colorStatus(val) {
      if (val == true) {
        return "green";
      } else {
        return "red";
      }
    },

    convertTags(val) {
      console.log(val.length);
      const array = val;
      let text = "";
      if (val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          if (i == 0) {
            text += val + " ";
          } else {
            text != val[i] + ", ";
          }
        }
      }
      console.log(text);
      return text;
    },
  },
});
